import React, { Component } from "react";

class Buscador extends Component {
  state = {};

  busquedaRef = React.createRef();

  obtenerDatos = (e) => {
    e.preventDefault();
    console.clear();
    // tomamos el valor del input
    const termino = this.busquedaRef.current.value;
    // console.log(termino);

    // lo devolvemos al componente Principal
    this.props.datosBusqueda(termino);
  };
  render() {
    return (
      <form onSubmit={this.obtenerDatos}>
        <div className="row">
          <div className="form-group col-md-8">
            <input
              ref={this.busquedaRef}
              type="text"
              className="form-control form-control-lg"
              placeholder="Busca tu imagen. Ejemplo: futbol"
            />
          </div>
          <div className="form-group col-md-4">
            <input
              type="submit"
              className="btn btn-primary btn-lg btn-block"
              value="Buscar"
            />
          </div>
        </div>
      </form>
    );
  }
}

export default Buscador;
