import React, { Component } from "react";
import Buscador from "./componentes/Buscador";
import Resultado from "./componentes/Resultado";

class App extends Component {
  state = {
    termino: "",
    pagina: "",
    imagenes: [],
  };

  scroll = () => {
    const elemento = document.querySelector(".jumbotron");
    elemento.scrollIntoView('smooth', 'start');
  }

  paginaAnterior = () => {
    // leer el state de la pagina
    let pagina = this.state.pagina;

    if (pagina === 1) {
      return null;
    }

    // restar 1 a la pagina
    pagina--;

    // Agregar cambio al state
    this.setState({
      pagina
    }, () => {
      this.consultarAPI();
      this.scroll();
    });

    // console.log(pagina);

  }

  paginaSiguiente = () => {
    // leer el state de la pagina
    let pagina = this.state.pagina;

    // sumar 1 a la pagina
    pagina++;

    // Agregar cambio al state
    this.setState({
      pagina
    }, () => {
      this.consultarAPI();
      this.scroll();
    });

    // console.log(pagina);

  }

  consultarAPI = () => {
    // 17250188-2543151698cc1e96c2653fc74
    const API_KEY = process.env.REACT_APP_PIXABAY_API_KEY;
    const url =
      "https://pixabay.com/api/?key=" + API_KEY + "&pretty=true&per_page=20&q=" +
      this.state.termino + "&page=" + this.state.pagina;
    // console.log(url);

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ imagenes: resultado.hits }));
  };

  datosBusqueda = (termino) => {
    // console.log(termino);
    this.setState(
      {
        termino: termino,
        pagina: 1,
      },
      () => {
        this.consultarAPI();
      }
    );
  };

  render() {
    return (
      <div className="app container">
        <div className="jumbotron">
          <p className="lead text-center">Buscador de imagenes</p>
          <Buscador datosBusqueda={this.datosBusqueda} />
        </div>
        <div className="row justify-content-center">
          <Resultado
            pagina={this.state.pagina}
            imagenes={this.state.imagenes}
            paginaAnterior={this.paginaAnterior}
            paginaSiguiente={this.paginaSiguiente}
          />
        </div>
      </div>
    );
  }
}

// const styles = {};

export default App;
